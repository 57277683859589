.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-image: url(https://ti-manager.com/homeBgSmaller.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header-div {
  background-color: white;
  border-radius: .1em;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  max-width: 70%;
  padding: .25em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.topBarDiv {
  display: flex;
  justify-content: space-between;
  font-size: 1.25em;
  margin: 1em 0 2em 0;
  padding: 0 1em 0 1em;
}

a {
  color: #00B7FF;
}
button {
  box-shadow: 0px 0px 3px black;
}
#errorDiv {
  color: red;
}
.inputPhoneHeader {
  font-size: .75em;
  text-align: center;
  padding: 0 2em 0 2em;
  margin: 2em 0 2em 0;
}
.form-check-label {
  font-size: .5em;
}
.link-font {
  font-size: .65em;
}
.card {
  padding: 1em;
  margin-top: 5em;
}
.copyPasteGroup {
  display: flex;
  justify-content: space-around;
}
.vertical-margin {
  margin-top: 1em;
  margin-bottom: 1em;
}
a.home-nav-link-focus {
  background-color: grey;
  border-radius: 5em;
  color: white;
}
.phone-input-div input {
  display: inline;
  width: 25%;
}
#feedbackDiv {
  font-size: .9rem;
  line-height: 1rem;
  margin: .25em;
  text-align: left;
}
#voteDiv {
  font-size: .9rem;
  line-height: 1rem;
  margin: .25em;
  text-align: left;
}
.nav {
  margin: .25em;
}
.margin-25 {
  margin: .25em;
}
